



































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from '@vue/composition-api';
import { AxiosInstance } from 'axios';
import { v4 as uuid } from 'uuid';
import validator from 'validator';

export default defineComponent({
  setup(_, { root }) {
    const form = ref<any>(null);
    const model = reactive({
      allowEditByParticipant: false,
      allowEditByParticipantDate: '' as string | null,
      emailCounters: '',
    });

    const state = reactive({
      success: false,
      error: false as boolean | number,
      valid: false,
      loading: false,
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc('layout.errors.noPermission')}`;
        case 404:
          return `${root.$tc('event.panel.config.additionalConfig.error404')}`;
        case 500:
          return `${root.$tc('layout.errors.500')}`;
        default:
          return `${root.$tc('layout.errors.default')}`;
      }
    };

    watch(
      () => model.allowEditByParticipant,
      () => {
        if (!model.allowEditByParticipant)
          model.allowEditByParticipantDate = null;
      },
    );

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        'api/getInstance'
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/config`)
        .then(({ data }) => {
          model.allowEditByParticipant = data.allowEditByParticipant;
          model.allowEditByParticipantDate = data.allowEditByParticipantDate?.split(
            'T',
          )[0];
          model.emailCounters = data.emailCounters;
          state.loading = false;
        })
        .catch((error) => {
          state.loading = false;
          console.log(error);
        });
    };

    onMounted(fetchData);

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        'api/getInstance'
      ] as AxiosInstance;

      const data = {
        allowEditByParticipant: model.allowEditByParticipant,
        allowEditByParticipantDate: model.allowEditByParticipant
          ? model.allowEditByParticipantDate
          : null,
        emailCounters: model.emailCounters,
      };

      axiosInstance
        .put(`event/${root.$route.params.id}/config`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;

          root.$store.commit('snackbar/PUSH_MESSAGE', {
            id: uuid(),
            color: 'primary',
            message: root.$tc('layout.misc.configSaved'),
          });
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit('snackbar/PUSH_MESSAGE', {
            id: uuid(),
            color: 'error',
            message: getErrorMessage(state.error as number),
          });
        });
    };

    const rules = {
      required: [(v: string) => !!v || `${root.$t('layout.misc.required')}`],
      email: [
        (v: string) =>
          !!v ||
          validator.isEmail(v) ||
          root.$tc('layout.misc.validEmailAddress'),
      ],
    };

    return { model, state, onSubmit, rules, form };
  },
});
